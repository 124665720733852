(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/marketplace/components/retail-game-view/assets/javascripts/retail-game-view.js') >= 0) return;  svs.modules.push('/modules/marketplace/components/retail-game-view/assets/javascripts/retail-game-view.js');
"use strict";


const {
  Loader
} = svs.components.ReactLoader;
const {
  selectors
} = svs.components.marketplaceData;
const {
  fetchActivatedDrawAction
} = svs.components.marketplaceData.games.actions;
const {
  fetchSingleDrawAction
} = svs.components.marketplaceData.draws.actions;
const {
  fetchBetProposal
} = svs.components.marketplaceData.betProposal.actions;
const {
  showDialog
} = svs.components.marketplaceData.dialogWrapper.actions;
const {
  DocumentTitle,
  getGameMyBetsUrl
} = svs.components.lbUtils;
const {
  ViewHeader
} = svs.components.marketplace.viewHeader;
const {
  GameTypeTag
} = svs.components.marketplace.gameTypeTag;
const {
  BoughtFractionsText
} = svs.components.lbUi;
const {
  FractionPayment
} = svs.components.marketplace.fractionPayment;
const {
  Button
} = svs.ui;
const {
  BetProposalContainer,
  excludedProducts
} = svs.components.marketplace.betProposal;
const {
  RacingEventInfo
} = svs.marketplace.components.retailGameView;
const {
  EventListContainer
} = svs.components.marketplace.eventList;
const {
  gameReceipt
} = svs.components.marketplaceShared;
const {
  getRetailUrl
} = svs.components.marketplace.helpers;
const {
  getSubProductDisplayName
} = svs.components.sport.tipsenShared;
const {
  getDisplayName,
  productIds
} = svs.utils.products;
const {
  getRacingDisplayName
} = svs.utils.racing;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_AM
} = svs.components.lbUtils.useMediaQuery;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  useHistory,
  useRouteMatch,
  useLocation
} = ReactRouterDOM;
const {
  useEffect,
  useRef,
  useMemo
} = React;
const REDIRECT_TO_MY_GAMES_DELAY = 200;
const RetailGameView = _ref => {
  var _history$state;
  let {
    chatWrapperRef
  } = _ref;
  const hasFetchedBetproposal = useRef(false);
  const hasFetchedDraws = useRef(false);
  useEffect(() => {
    if (chatWrapperRef !== null && chatWrapperRef !== void 0 && chatWrapperRef.current) {
      chatWrapperRef.current.scrollTo(0, 0);
    } else if (document.querySelector('.chat-detail-scroll')) {
      document.querySelector('.chat-detail-scroll').scrollTo(0, 0);
    }
  }, []);
  const hasFractionsPayQuery = useRef(false);
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const groupId = Number(match.params.groupId);
  const activatedDrawId = Number(match.params.activatedDrawId);
  const containerId = Number(match.params.containerId);
  const memberId = useSelector(state => state.customerId);
  const game = useSelector(state => selectors.games.getGame(state, {
    activatedDrawId,
    groupId
  })) || {};
  const {
    loading,
    wagerSerial,
    error,
    drawNumber,
    isSubmitted,
    productId,
    productName,
    containerDescription,
    description,
    subProductId,
    isFinished,
    isFavorite,
    richDescription,
    name,
    maxFractionsPerMember
  } = game;
  const hasGameInMemory = useSelector(state => selectors.games.selectHasGameInMemory(state, {
    activatedDrawId,
    groupId
  }));
  const currentPurchasedFractions = useSelector(state => selectors.games.getPurchasedFractionsForMember(state, {
    activatedDrawId,
    memberId: state.customerId,
    groupId
  })) || 0;
  const {
    data: betProposal
  } = useSelector(state => {
    var _state$BetProposal$ac;
    return activatedDrawId && state.BetProposal && !((_state$BetProposal$ac = state.BetProposal[activatedDrawId]) !== null && _state$BetProposal$ac !== void 0 && _state$BetProposal$ac.isPrivate) && state.BetProposal[activatedDrawId] || [];
  });
  const team = useSelector(state => selectors.teams.selectTeam(state, {
    id: groupId
  })) || {};
  const {
    name: drawName
  } = useSelector(state => selectors.draws.getDraw(state, {
    drawNumber,
    productName,
    productId
  })) || {};
  const isSmall = useMediaQuery(breakpoints.down(BREAKPOINT_AM));
  const gameIsLoading = loading || !hasGameInMemory && !error;
  const isCurrentUserAuthenticated = Boolean(memberId);
  useEffect(() => {
    if (!hasFractionsPayQuery.current) {
      if (location.search.includes('fractionData=')) {
        hasFractionsPayQuery.current = true;
      }
    }
  }, [hasFractionsPayQuery]);
  useEffect(() => {
    dispatch(fetchActivatedDrawAction(groupId, activatedDrawId, containerId));
  }, [activatedDrawId, containerId, dispatch, groupId]);
  useEffect(() => {
    if (activatedDrawId && !hasFetchedBetproposal.current) {
      dispatch(fetchBetProposal(activatedDrawId, groupId, productName));
      hasFetchedBetproposal.current = true;
    }
  }, [activatedDrawId, dispatch, groupId, hasFetchedBetproposal, productName]);
  useEffect(() => {
    if (productId && drawNumber && !hasFetchedDraws.current) {
      dispatch(fetchSingleDrawAction(productId, drawNumber));
      hasFetchedDraws.current = true;
    }
  }, [dispatch, drawNumber, productId]);
  const hasSelectedOutcomes = useMemo(() => Object.keys(betProposal || {}).filter(event => Object.keys(betProposal[event]).filter(outcome => Boolean(betProposal[event][outcome])).length > 0).length > 0, [betProposal]);
  if (!gameIsLoading && (error || !hasGameInMemory)) {
    return React.createElement("div", null, React.createElement("h1", null, "N\xE5got gick snett!"));
  }
  if (gameIsLoading) {
    return React.createElement("div", {
      "data-testid": "retail-game-view-loading"
    }, React.createElement(Loader, {
      className: "retail-game-view-spinner",
      inverted: true,
      size: 300
    }));
  }
  const renderBetProposalArea = () => {
    if (!isSubmitted && hasSelectedOutcomes) {
      return React.createElement(BetProposalContainer, {
        activatedDrawId: activatedDrawId,
        groupId: groupId
      });
    }
    if (productId === productIds.RACING) {
      return React.createElement("div", {
        className: "grid-row"
      }, React.createElement(RacingEventInfo, null));
    }
    return React.createElement(EventListContainer, {
      activatedDrawId: activatedDrawId,
      drawNumber: drawNumber,
      groupId: groupId,
      warningMessage: excludedProducts[productName] ? undefined : 'Butiken har valt att inte visa radskiss.'
    });
  };
  const handleNavigateToMyBets = () => {
    dispatch(showDialog({
      title: 'Vidarebefordrar till Mina spel...',
      icon: 'nav-my-games'
    }));
    const startTime = Date.now();
    getGameMyBetsUrl({
      productId,
      wagerSerial,
      drawNumber
    }, (error, targetUrl) => {
      if (error) {
        dispatch(showDialog({
          title: 'Vi kunde tyvärr inte hämta länken till spelet.',
          type: 'error'
        }));
      } else {
        const timePassed = Date.now() - startTime;
        const remainingDelay = Math.max(0, REDIRECT_TO_MY_GAMES_DELAY - timePassed);
        setTimeout(() => window.location.pathname = targetUrl, remainingDelay);
      }
    });
  };
  const handleGoToTeam = () => {
    history.push({
      pathname: getRetailUrl(groupId, team.name),
      search: location.search
    });
  };
  const handleGoBack = () => {
    svs.utils.url.refreshQueryString();
    const returnUrl = svs.utils.url.queryString.returnUrl;
    if (returnUrl !== null && returnUrl !== void 0 && returnUrl.includes('https')) {
      window.location.href = returnUrl;
    } else if (returnUrl) {
      history.push(returnUrl);
    } else if (hasFractionsPayQuery) {
      history.push(getRetailUrl(groupId, team.name));
    } else {
      history.goBack();
    }
  };
  const subTitles = [];
  if (productId && drawName) {
    const subProductName = getSubProductDisplayName(subProductId);
    if (subProductName.length === 0) {
      const displayName = productId === productIds.RACING ? getRacingDisplayName(productId) : getDisplayName(productId);
      subTitles.push("".concat(displayName, " ").concat(drawName));
    } else {
      subTitles.push(drawName);
    }
  }
  const fractionPaymentClasses = 'margin-top-1 padding-bottom-1 pay-button-container';
  const ActivatedDrawButtons = () => {
    if (isSubmitted && currentPurchasedFractions > 0) {
      return React.createElement("div", {
        className: "button-container"
      }, React.createElement(Button, {
        block: isSmall,
        className: "qa-show-my-game",
        inverted: true,
        onClick: handleNavigateToMyBets
      }, "Visa i mina spel"));
    } else if (!isSubmitted && !isSmall) {
      return React.createElement("div", {
        className: ""
      }, React.createElement(FractionPayment, {
        activatedDrawId: activatedDrawId,
        className: fractionPaymentClasses,
        containerId: containerId,
        game: game,
        groupId: groupId,
        memberId: memberId
      }));
    }
    return null;
  };

  const hasCloseButton = !(history !== null && history !== void 0 && (_history$state = history.state) !== null && _history$state !== void 0 && _history$state.hasBackButton) && !hasFractionsPayQuery;
  return React.createElement("div", {
    className: "retail-game-view-container ".concat(isSmall ? '' : 'margin-right-1'),
    "data-testid": "retail-game-view-container"
  }, React.createElement(DocumentTitle, {
    title: "".concat(name, " \u2014 Spela tillsammans")
  }), React.createElement(ViewHeader, {
    boldDescription: containerDescription,
    description: description,
    gameTag: React.createElement(GameTypeTag, {
      productId: productId,
      subProductId: subProductId
    }),
    hasCloseButton: hasCloseButton,
    isFavorite: isFavorite,
    onGoBack: handleGoBack,
    onGoToTeam: handleGoToTeam,
    productId: productId,
    receiptFields: gameReceipt.getGameReceipt(game, team),
    richDescription: richDescription,
    subProductId: subProductId,
    subTitle: subTitles,
    teamName: team.name,
    title: name
  }), React.createElement("div", {
    className: "retail-game-view-body"
  }, React.createElement("div", {
    className: "f-center margin-2"
  }, isCurrentUserAuthenticated ? React.createElement(BoughtFractionsText, {
    currentPurchasedFractions: currentPurchasedFractions,
    isPastTime: isFinished,
    isSubmitted: isSubmitted,
    maxFractionsPerMember: maxFractionsPerMember
  }) : null, React.createElement(ActivatedDrawButtons, null)), renderBetProposalArea(), isSmall && !game.isSubmitted && React.createElement(FractionPayment, {
    activatedDrawId: game.activatedDrawId,
    className: fractionPaymentClasses,
    containerId: containerId,
    game: game,
    groupId: groupId,
    hasSnackbar: true,
    memberId: memberId
  })));
};
setGlobal('svs.marketplace.components.retailGameView.RetailGameView', RetailGameView);

 })(window);