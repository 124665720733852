(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/marketplace/components/retail-game-view/assets/javascripts/racing-event-info.js') >= 0) return;  svs.modules.push('/modules/marketplace/components/retail-game-view/assets/javascripts/racing-event-info.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  RacePickerItemLight
} = svs.racing;
const {
  useRace
} = svs.components.marketplaceShared.hooks;
const {
  Image
} = svs.content.image_v2;
const {
  ImageTypes,
  getImageOptions
} = svs.racing.common.imageOptions;
const {
  useParams
} = ReactRouterDOM;
const RacingEventInfo = () => {
  const {
    groupId,
    activatedDrawId
  } = useParams();
  const {
    race,
    meeting,
    draw
  } = useRace(Number(activatedDrawId), Number(groupId));
  if (meeting && race && race.participants) {
    const {
      silksFormattedDate
    } = meeting;
    return React.createElement("div", {
      className: "col-xs-12 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 col-xl-4 col-xl-offset-4"
    }, React.createElement(RacePickerItemLight, {
      className: "margin-bottom-1",
      meeting: meeting,
      raceSummary: _objectSpread(_objectSpread({}, race), {}, {
        draws: [draw]
      })
    }), race.participants.map(_ref => {
      let {
        cancelled,
        participantId,
        silks,
        participantNumber,
        horseName,
        driverName
      } = _ref;
      const imageOptions = silks && silksFormattedDate && getImageOptions(silks, silksFormattedDate, ImageTypes.CouponSilk);
      return React.createElement("div", {
        className: "padding-v-1 racing-event-info-item ".concat(cancelled ? 'racing-event-info-item-cancelled' : ''),
        key: participantId
      }, React.createElement("div", {
        className: "racing-event-info-image margin-right-1"
      }, imageOptions && React.createElement(Image, {
        image: imageOptions
      })), React.createElement("div", null, React.createElement("div", {
        className: "racing-event-info-horse-name"
      }, participantNumber, ".", ' ', horseName.toUpperCase()), React.createElement("div", {
        className: "racing-event-info-driver-name"
      }, driverName)));
    }));
  }
  return null;
};
setGlobal('svs.marketplace.components.retailGameView.RacingEventInfo', RacingEventInfo);

 })(window);